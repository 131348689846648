import ElementData from "../components/ElementData";
import { useParams, Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;

const Element = () => {
  let { groupElementId, levelId } = useParams();
  const [technicName, setTechnicName] = useState("");
  const [levelName, setLevelName] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseLevel = await axios.get(apiUrl + `levels/${levelId}`);
        const responseTechnic = await axios.get(apiUrl + `group_elements/${groupElementId}`);
        setLevelName(responseLevel.data);
        setTechnicName(responseTechnic.data);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchData();
  }, [levelId]); // Effect se déclenche à chaque changement d'ID

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

    return (
      <div className="TechnicContainer">
        <div className="Title">
        <Link className="BackButton" to={{
                pathname: "/technic/" + groupElementId,
            }}>
                <img src="./back.png" />
            </Link>
          <div className="TitleElement">
        
            <a>{technicName.toUpperCase()}</a>
            <a>{levelName.toUpperCase()}</a>
            
          </div>
        </div>
        
        
        <div className="ElementArea">
          <ElementData groupElementId={groupElementId} levelId={levelId}/>
        </div>

        {sessionStorage.getItem("role") == 1 ? (
            <Link className="AddButton" to={{
              pathname: "/technic/" + groupElementId + "/level/" + levelId + "/element_new",
          }}>
              <img src="./plus.png" />
          </Link>
          ) : (
            null
          )}
        
      </div>
      )
    };  
    export default Element;
import { Link } from "react-router-dom";
import axios from "axios";
import React, { useState, useEffect } from 'react';
const apiUrl = process.env.REACT_APP_API_URL;
const fileUrl = process.env.REACT_APP_FILE_URL;

const Music = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [volume, setVolume] = useState(0.2); 
  const [currentTrack, setCurrentTrack] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [tracks, setTracks] = useState([])
  const [musics, setMusics] = useState([])
  const [tracksName, setTracksName] = useState([])
  const [tracksImage, setTracksImage] = useState([])
  const [showPlaylist, setShowPlaylist] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(apiUrl + `musics`);
        setTracks(response.data.map(track => track.filename.substring(0, track.filename.length - 4)));
        setTracksName(response.data.map(track => track.name));
        setTracksImage(response.data.map(track => track.image_filepath));
        setMusics(response.data);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };
    
    fetchData();
  }, []);

  useEffect(() => {
    if (!loading && error == null) {
      const audioElement = document.getElementById('myAudio');
      if(audioElement != null){
        audioElement.volume = volume;
  
      // Mise à jour de l'état de la durée totale de la piste lorsque les métadonnées de la piste sont chargées
      audioElement.addEventListener('loadedmetadata', () => {
        setDuration(audioElement.duration);
      });
  
      // Mise à jour de l'état du temps de lecture lors de la lecture de la musique
      audioElement.addEventListener('timeupdate', () => {
        setCurrentTime(audioElement.currentTime);
      });
  
      // Nettoyage de l'écouteur d'événements lorsque le composant est démonté
      return () => {
        audioElement.removeEventListener('loadedmetadata', () => {});
        audioElement.removeEventListener('timeupdate', () => {});
      };
    }
    }
  }, [loading, error]);
  
  // Fonction pour basculer la lecture de la musique
  function togglePlay() {
    const audioElement = document.getElementById('myAudio');
    if (isPlaying) {
      audioElement.pause();
    } else {
      audioElement.play();
    }
    setIsPlaying(!isPlaying);
  }

  function handleVolumeChange(event) {
    const newVolume = event.target.value;
    setVolume(newVolume);
    const audioElement = document.getElementById('myAudio');
    audioElement.volume = newVolume;
  }

  function handleNextTrack() {
    setIsPlaying(false);
    const nextTrackIndex = (currentTrack + 1) % tracks.length; // Calcul de l'indice de la piste suivante
    setCurrentTrack(nextTrackIndex);
    playTrack(nextTrackIndex)
  }

  function handlePreviousTrack() {
    setIsPlaying(false);
    const previousTrackIndex = (currentTrack - 1 + tracks.length) % tracks.length; // Calcul de l'indice de la piste précédente
    setCurrentTrack(previousTrackIndex);
    playTrack(previousTrackIndex)
  }

  function switchTrack(trackIndex) {
    setIsPlaying(false);
    setCurrentTrack(trackIndex);
    playTrack(trackIndex)
  }

  function playTrack(trackIndex) {
    const audioElement = document.getElementById('myAudio');
    audioElement.src = fileUrl + "musics/" + tracks[trackIndex] + ".mp3"; // Changement de la source audio vers la piste spécifiée

  }

  function handleSeek(event) {
    const audioElement = document.getElementById('myAudio');
    const seekTime = event.target.value;
    audioElement.currentTime = seekTime;
  }
  
  function toggleOpenState() {
    setShowPlaylist(!showPlaylist);
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const onPressDelete = (music_id) => {
    const onSuccess = () => {
      window.location.reload();
    };

    const onFailure = (error) => {
      console.log(error && error.response);
      
    };
    axios.delete(apiUrl + "del_music/" + music_id).then(onSuccess).catch(onFailure)
  }

  return (
    
    <div className="MusicContainer">
      <h1>Musique</h1>
      <div className="MusicArea">

        <audio id="myAudio" loop >
          {/* Chaque élément <source> doit être fermé correctement */}
          <source src={fileUrl + "musics/" + tracks[currentTrack] + ".mp3"} type="audio/mpeg" />
          <p>Votre navigateur ne prend pas en charge l'élément audio.</p>
        </audio>

        <div className="MusicImage">
            <img className="MusicImage" src={fileUrl + "musics/" + tracksImage[currentTrack]} alt="image de la musique"></img>
          </div>
        <div className="MusicTitre">
        <a>{tracksName[currentTrack]}</a>
        </div>
          <input className="MusicAvancement"
            type="range"
            min="0"
            max={duration}
            value={currentTime} // Assurez-vous de diviser par la durée totale pour obtenir une valeur entre 0 et 1
            onChange={handleSeek}
          />
        <div className="MusicTimer">
          <a>{Math.floor(currentTime/60) + ":" + Math.floor(currentTime%60)}</a>
          <a>{Math.floor(duration/60) + ":" + Math.floor(duration%60)}</a>
        </div>
        

        <div className="MusicControls">
          <a className="MusicBefore" onClick={handlePreviousTrack}>
            <img className="MusicButton" src='./previous.png'></img>
          </a>
          <a className="MusicPlayPause" onClick={togglePlay}>
            <img className="MusicButton" src={isPlaying ? './pause.png' : './play.png'}></img>
          </a>
          <a className="MusicNext" onClick={handleNextTrack}>
            <img className="MusicButton" src='./next.png'></img>
          </a>
        </div>
          <div className="MusicVolumeArea">
            
          <img className="MusicIcon" src='./mute.png'></img>
            <input
              className="MusicVolume"
              type="range"
              id="volume"
              name="volume"
              min="0"
              max="1"
              step="0.05"
              value={volume}
              onChange={handleVolumeChange}
            />
            <img className="MusicIcon" src='./sound.png'></img>
        </div>

        <div className="MusicPlaylist">
        <h2 className="PlaylistTitle no-select" onClick={() => toggleOpenState()}>PlayList</h2>
          {showPlaylist && musics.map((element, index) => (
            <div className="PlaylistMusicName" onClick={() => switchTrack(index)}>
              <a key={index}>{index+1 + "." }{element.name}</a>
              {sessionStorage.getItem("role") == 1 ? (
                <img
                  className="icon_trashMusic selectable"
                  onClick={() => onPressDelete(element.id)}
                  src="./trash.png"
                />
              ) : (
                null
              )}
            </div>
          ))}
          {sessionStorage.getItem("role") == 1 ? (
            <Link className="AddButtonMusic" to={{
                pathname: "/music_new/",
            }}>
                <img src="./plus.png" />
            </Link>
          ) : (
            null
          )}
          
        </div>
        
      </div>
      
    </div>
    )
  };  
  export default Music;